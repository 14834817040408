<script setup lang="ts">
import {
    AntlerClasses,
    installAntlerComponent,
    responsive,
} from "@/Utils/component";
import AppIcon from "@/Components/Shared/icon/AppIcon.vue";

export type AvatarSize =
    | "xxsmall"
    | "xsmall"
    | "small"
    | "regular"
    | "large"
    | "xlarge"
    | "xxlarge";
export type AvatarVariant = "bordered" | "contained";

type Props = {
    src: string;
    size?: AvatarSize;
    variant?: AvatarVariant;
};

const { src, size = "regular", variant = "" } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "inline-flex items-center shrink-0 justify-center basis-12 w-12 h-12 rounded-full ring-2 ring-white bg-gray-100 overflow-hidden",
    variants: {
        size: {
            xxsmall: responsive("w-4 h-4 basis-4"),
            xsmall: responsive("w-6 h-6 basis-6"),
            small: responsive("w-8 h-8 basis-8"),
            regular: responsive("w-12 h-12 basis-12"),
            large: responsive("w-16 h-16 basis-16"),
            xlarge: responsive("w-20 h-20 basis-20"),
            xxlarge: responsive("w-32 h-32 basis-32"),
        },
        variant: {
            bordered: "border-2 border-white",
            contained: "object-contain",
        },
    },
};
const { aClass } = installAntlerComponent("avatar", { size, variant }, classes);
</script>

<template>
    <div :class="[aClass({ 'text-white': !src })]">
        <AppIcon
            v-if="!src"
            name="user"
        />
        <img
            v-else
            class="w-full h-full object-cover"
            alt="avatar"
            :src="src"
        />
    </div>
</template>
